import { useRef, useState } from "react";
import styled, { ThemeProp, css } from "../styles";
import { Card } from "./Card";
import { Flex } from "./Flex";
import { View } from "./View";
import { InfoIcon } from "./icons/InfoIcon";

export const Tooltip = ({
  children,
  width,
}: {
  children: React.ReactNode;
  width?: number;
}) => {
  const [position, setPosition] = useState<"above" | "below">("below");
  const ref = useRef<HTMLDivElement>(null);

  const handleMouseEnter = () => {
    if (!ref.current) return;

    const rect = ref.current.getBoundingClientRect();
    const popoverHeight = 160;

    if (rect.top < popoverHeight) {
      setPosition("below");
    } else {
      setPosition("above");
    }
  };

  return (
    <>
      <Wrap
        ref={ref}
        onMouseEnter={handleMouseEnter}
        align="center"
        justify="center"
      >
        {<InfoIcon colorPreset="secondary" />}
        <PopoverContent
          width={width}
          className="popover"
          position={position}
          onClick={(e: any) => e.stopPropagation()}
        >
          <StyledCard position={position}>
            <View padding="m">{children}</View>
          </StyledCard>
        </PopoverContent>
      </Wrap>
    </>
  );
};

const Wrap = styled(Flex)`
  position: relative;
  cursor: pointer;
  top: -2px;

  &:hover {
    .popover {
      display: block;
    }
  }
`;

const PopoverContent = styled.div<{
  position: "above" | "below";
  width?: number;
}>`
  display: none;
  position: absolute;
  width: ${(p: any) => p.width || 280}px;
  z-index: 999;
  padding: 10px 0;

  cursor: auto;

  left: -${(p: any) => (p.width ? p.width / 2 - 8 : 132)}px;

  ${(p: any) =>
    p.position === "above"
      ? css`
          bottom: 100%;
        `
      : css`
          top: 100%;
        `}
`;

const StyledCard = styled(Card)<{ position: "above" | "below" }>`
  box-shadow: ${(p: ThemeProp) => p.theme.shadow.popover};
  user-select: none;

  &:after {
    ${(p: any) =>
      p.position === "below"
        ? css`
            content: "";
            width: 12px;
            height: 12px;
            background: inherit;
            z-index: 1;
            top: 6px; // half height minus card margin top
            left: calc(50% - 6px);
            display: block;
            position: absolute;
            transform: rotateZ(45deg);
          `
        : css`
            content: "";
            width: 12px;
            height: 12px;
            background: inherit;
            z-index: 1;
            bottom: 6px;
            left: calc(50% - 6px);
            display: block;
            position: absolute;
            transform: rotateZ(45deg);
          `}
  }
`;
