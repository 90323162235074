import React from "react";
import Masonry from "react-masonry-css";
import styled from "styled-components";

const MasonryGrid = styled(Masonry)`
  &.my-masonry-grid {
    display: flex;
    width: 100%;

    div {
      &.my-masonry-grid_column {
        box-sizing: border-box;
        padding: 0;
        background-clip: padding-box;

        div {
          box-sizing: border-box;
          padding: 0 2px;
          margin: 0;
        }
      }
    }
  }
`;

interface Props {
  children: React.ReactNode;
}

const MasonryList = (props: Props) => {
  const breakpoints = {
    default: 8,
    2200: 8,
    1920: 7,
    1600: 6,
    1280: 4,
    900: 3,
    500: 2,
  };

  return (
    <MasonryGrid
      breakpointCols={breakpoints}
      className="my-masonry-grid"
      columnClassName="my-masonry-grid_column"
    >
      {props.children}
    </MasonryGrid>
  );
};

export default MasonryList;
