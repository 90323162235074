import { Dialog } from "@headlessui/react";
import { MOBILE_BREAKPOINT } from "../config";
import styled, { css } from "../styles";
import { Card } from "./Card";
import { CloseIcon } from "./CloseIcon";

interface ModalProps {
  children: React.ReactNode;
  isOpen: boolean;
  setIsOpen?: (arg0: boolean) => void;
  onClose?: () => void;
  maxWidth?: number;
  noPadding?: boolean;
}

export function Modal({
  isOpen,
  setIsOpen,
  children,
  onClose,
  maxWidth,
  noPadding,
}: ModalProps) {
  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        setIsOpen && setIsOpen(false);
        onClose && onClose();
      }}
    >
      <Backdrop aria-hidden="true">
        <CenteredContainer maxWidth={maxWidth} noPadding={noPadding}>
          {setIsOpen ? (
            <AbsoluteWrap onClick={() => setIsOpen(false)}>
              <CloseIcon width={28} height={28} />
            </AbsoluteWrap>
          ) : null}
          <Dialog.Panel style={{ width: "100%", height: "100%" }}>
            {children}
          </Dialog.Panel>
        </CenteredContainer>
      </Backdrop>
    </Dialog>
  );
}

const CenteredContainer = styled(Card)<{
  maxWidth?: number;
  noPadding?: boolean;
}>`
  display: flex;
  align-items: flex-start;
  width: ${(p) => (p.maxWidth ? p.maxWidth : 600)}px;
  max-width: 100%;
  min-height: 120px;
  max-height: 80vh;
  overflow-y: scroll;
  position: relative;
  outline: none !important;

  [data-headlessui-state="open"] *:focus {
    outline: none !important;
  }

  ${(p) =>
    p.noPadding
      ? css`
          padding: 0;
        `
      : css`
          padding: ${(p) => p.theme.spacing.xl};

          @media (max-width: ${MOBILE_BREAKPOINT}px) {
            padding: ${(p) => p.theme.spacing.l};
          }
        `}
`;

const AbsoluteWrap = styled.div`
  position: absolute;
  top: ${(p) => p.theme.spacing.xl};
  right: ${(p) => p.theme.spacing.xl};
  cursor: pointer;

  svg {
    path {
      fill: ${(p) => p.theme.color.typography.text};

      &:hover {
        fill: ${(p) => p.theme.color.typography.heading};
    }
  }
`;

const Backdrop = styled.div`
  position: fixed;
  inset: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 ${(p) => p.theme.spacing.m};
`;
