import { useEffect, useRef, useState } from "react";
import { useWindowSize } from "../hooks/useWindowSize";
import styled, { css } from "../styles";

const INSET_SPACING = 3;

const Container = styled.div`
  width: 100%;
  box-sizing: border-box;
  user-select: none;

  & * {
    z-index: 2;
  }

  padding: 0 ${() => INSET_SPACING}px;

  background: ${(p) => p.theme.color.brandGradient};
  border-radius: 20px;
`;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex: 1;
`;

const Puck = styled.div`
  background: white;
  position: absolute;
  top: ${INSET_SPACING}px;
  bottom: ${INSET_SPACING}px;
  width: 0;
  opacity: 0;
  transition: transform 200ms cubic-bezier(0.76, 0, 0.24, 1), width 200ms linear,
    opacity 150ms linear 500ms;
  border-radius: 20px;
  background: rgba(255, 255, 255, 0.95);
`;

const Toggle = styled.div`
  font-weight: 600;
  font-size: 12px;
  height: 28px;
  position: relative;
  flex: 1;
  justify-content: center;
  align-items: center;

  display: flex;

  transition: all 200ms linear;

  cursor: pointer;

  ${(p: { active?: boolean }) =>
    p.active
      ? css`
          color: #000;
        `
      : css`
          color: #fff;
        `}
`;

const NotificationCount = styled.span`
  background-color: #6c45c6;
  border-radius: 999px;
  position: absolute;
  right: ${(p) => p.theme.spacing.s};
  padding: 0 ${(p) => p.theme.spacing.s};
  font-size: ${(p) => p.theme.typography.size.xs};

  ${(p: { active?: boolean }) =>
    p.active
      ? css`
          background-color: ${(p) => p.theme.color.additional.darkPurple}25;
          color: #000;
        `
      : css`
          background-color: ${(p) => p.theme.color.additional.darkPurple};
          color: #fff;
        `}
`;

export type SegmentedControlProps<T> = {
  value: T;
  options: SegmentOptions<T>[];
  onChange: (value: T) => void;
  className?: any;
};

export type SegmentOptions<T> = {
  label: string;
  value: T;
  count?: number;
};

export function SegmentedControl<T>(props: SegmentedControlProps<T>) {
  const activeRef = useRef<HTMLDivElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [x, setX] = useState(0);
  const [width, setWidth] = useState(0);
  const [showPuck, setShowPuck] = useState(false);
  const size = useWindowSize();

  function reposition() {
    if (!activeRef.current || !wrapperRef.current) {
      return;
    }

    const wrapperRect = wrapperRef.current.getBoundingClientRect();
    const activeRect = activeRef.current.getBoundingClientRect();

    setX(activeRect.left - wrapperRect.left);
    setWidth(activeRect.width);

    setShowPuck(true);
  }

  useEffect(() => {
    reposition();
  }, [props.value, size]);

  return (
    <Container className={props.className}>
      <Wrapper ref={wrapperRef}>
        <Puck
          style={{
            opacity: showPuck ? 1 : 0,
            transform: `translate3d(${x}px, 0, 0)`,
            width,
          }}
        />
        {props.options.map((d) => {
          const isActive = d.value === props.value;
          return (
            <Toggle
              ref={isActive ? activeRef : null}
              active={isActive}
              onClick={(e) => props.onChange(d.value)}
              key={d.label + d.value}
            >
              {d.label}
              {d.count && (
                <NotificationCount active={isActive}>
                  {d.count}
                </NotificationCount>
              )}
            </Toggle>
          );
        })}
      </Wrapper>
    </Container>
  );
}
