import {
  FileUploaderRegular,
  OutputCollectionState,
  OutputCollectionStatus,
  UploadCtxProvider,
} from "@uploadcare/react-uploader";
import "@uploadcare/react-uploader/core.css";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "sonner";
import Avatar from "../../components/Avatar";
import { Button, Submit } from "../../components/CTA";
import { Card } from "../../components/Card";
import { Dropdown } from "../../components/Dropdown";
import { Flex } from "../../components/Flex";
import { H1 } from "../../components/Heading";
import { Input } from "../../components/Input";
import Loading from "../../components/Loading";
import { Subtitle } from "../../components/Subtitle";
import { Text } from "../../components/Text";
import { TextArea } from "../../components/TextArea";
import { View } from "../../components/View";
import { MOBILE_BREAKPOINT } from "../../config";
import {
  AccountType,
  CancellationNotificationPolicy,
  useGetBrandQuery,
  useUpdateBrandMutation,
} from "../../graphql/generated";
import useAnalytics from "../../hooks/useAnalytics";
import useGqlClient from "../../hooks/useGqlClient";
import { authSelectors } from "../../store/auth/selector";
import { styled } from "../../styles";
import { brandCategories } from "./signup/DescribeBrand";

const cancellationPolicyOptions = [
  {
    value: CancellationNotificationPolicy.CancellationNotificationPolicyNever,
    label: "No",
  },
  {
    value:
      CancellationNotificationPolicy.CancellationNotificationPolicy_24Hours,
    label: "Yes",
  },
];

const Wrap = styled.div`
  width: 600px;
  max-width: 100%;
  text-align: left;
  box-sizing: border-box;
  height: 100%;
  margin-bottom: ${(p) => p.theme.spacing.xl};

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    padding: 0 ${(p) => p.theme.spacing.l};
  }
`;

export interface MatchParams {
  page: string;
}

const StyledCard = styled(Card)`
  padding: ${(p) => p.theme.spacing.xl};

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    padding: ${(p) => p.theme.spacing.l};
  }

  overflow: visible;
`;

export const BrandProfile = () => {
  return (
    <Wrap>
      <H1 margin="0 0 0">Brand Profile</H1>
      <Subtitle margin="xs 0 l 0">
        Update your brand logo and details here
      </Subtitle>

      <View padding="0 0 xl">
        <StyledCard>
          <BrandForm />
        </StyledCard>
      </View>
    </Wrap>
  );
};

const BrandForm = () => {
  const { track } = useAnalytics();
  const [selectedType, setSelectedType] = useState<string[]>([]);
  const [brandName, setBrandName] = useState("");
  const [tagline, setTagline] = useState("");
  const [longDescription, setLongDescription] = useState("");
  const [longDescriptionLength, setLongDescriptionLength] = useState<number>(0);
  const [brandLogo, setBrandLogo] = useState<string | null | undefined>("");
  const [cancellationPolicy, setCancellationPolicy] =
    useState<CancellationNotificationPolicy>(
      CancellationNotificationPolicy.CancellationNotificationPolicy_24Hours
    );
  const client = useGqlClient();
  const updateBrand = useUpdateBrandMutation(client, {});
  const activeBrandId = useSelector(authSelectors.activeBrandId);
  const [taglineLength, setTaglineLength] = useState<number>(0);
  const [taglineError, setTaglineError] = useState("");
  const uploaderRef = useRef<InstanceType<UploadCtxProvider> | null>(null);

  const { data: brand } = useGetBrandQuery(client, {
    id: activeBrandId ? activeBrandId : "",
  });

  const handleImageUpload = (
    files: OutputCollectionState<OutputCollectionStatus, "maybe-has-group">
  ) => {
    console.log(files);
    if (files.successEntries.length > 0) {
      const newImages = [];
      for (const file of files.successEntries) {
        console.log("file: ", file);
        if (file.cdnUrl) {
          newImages.push(file.cdnUrl);
        }
      }
      setBrandLogo(newImages[0]);
      updateBrand.mutate(
        {
          input: {
            id: activeBrandId ? activeBrandId : "",
            logo: newImages[0],
          },
        },
        {
          onError: (e: any) => {
            console.error(e);
          },
          onSuccess: (res) => {
            toast.success("Logo updated");
            track("Logo updated", {
              id: activeBrandId ? activeBrandId : undefined,
              brand: brandName,
            });
          },
        }
      );
    }

    uploaderRef.current?.api.removeAllFiles();
  };

  const account = useSelector(authSelectors.account);

  useEffect(() => {
    if (brand && brand.brand) {
      setBrandName(brand.brand.name);
      setSelectedType(brand.brand.categories.map((c) => c.category));
      setBrandLogo(brand.brand.logo);
      setTaglineLength(brand.brand.description.length);
      setTagline(brand.brand.description);
      setLongDescription(brand.brand.longDescription);
      setLongDescriptionLength(brand.brand.longDescription.length);
      setCancellationPolicy(brand.brand.cancellationNotificationPolicy);
    }
  }, [brand]);

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!brand || !activeBrandId) {
      return;
    }

    updateBrand.mutate(
      {
        input: {
          id: activeBrandId,
          name: brandName,
          categories: selectedType,
          logo: brandLogo,
          description: tagline,
          longDescription: longDescription,
          cancellationPolicy: cancellationPolicy,
        },
      },
      {
        onError: (e: any) => {
          console.error(e);
        },
        onSuccess: (res) => {
          toast.success("Brand profile updated");
          track("Brand updated", {
            id: activeBrandId ? activeBrandId : undefined,
            brand: brandName,
            categories: selectedType,
            description: tagline,
            longDescription: longDescription,
          });
        },
      }
    );
  };

  if (!brand || !brand.brand) {
    return <Loading />;
  }

  return (
    <View>
      <form onSubmit={onSubmit}>
        <View margin="0 0 xl 0">
          <Flex dir="row" align="center">
            <View margin="xs 0 0">
              <Avatar
                style={{ cursor: "unset" }}
                withShadow={false}
                borderSize={0}
                size={68}
                url={
                  brandLogo
                    ? brandLogo
                    : "https://wenibble-images.s3.eu-central-1.amazonaws.com/tiktok_avatars/empty.jpeg"
                }
              />
            </View>
            <div>
              <View margin="0 0 0 xl">
                <Text weight="semi" margin="0 0 xs">
                  Brand Logo
                </Text>
                <Flex>
                  <div>
                    <Button
                      size="s"
                      buttonType="secondary"
                      onClick={() => uploaderRef.current?.api.initFlow()}
                    >
                      Upload logo
                    </Button>
                  </div>
                </Flex>
              </View>
            </div>
          </Flex>
        </View>
        <Input
          label="Brand Name"
          defaultValue={brand.brand.name}
          name="brandName"
          help="This will be displayed on the Joli app"
          onChange={(e) => setBrandName(e.target.value)}
          margin="0 0 xl 0"
        />
        <TextArea
          label="Description (optional)"
          margin="0 0 l 0"
          name="longDescription"
          rows={3}
          placeholder="Write a short description of your brand..."
          characterLimit={160}
          characterLength={longDescriptionLength}
          onChange={(e) => {
            setLongDescriptionLength(e.currentTarget.value.length);
            setLongDescription(e.currentTarget.value);
          }}
          defaultValue={brand.brand.longDescription}
        />
        {account && account.type === AccountType.AccountTypeStaff ? (
          <TextArea
            label="Tagline [Visible ONLY to Joli Staff]"
            margin="0 0 l 0"
            name="description"
            help="The short description shown on the Joli app"
            rows={2}
            characterLimit={38}
            characterLength={taglineLength}
            onChange={(e) => {
              setTaglineError("");
              setTaglineLength(e.currentTarget.value.length);
              setTagline(e.currentTarget.value);
            }}
            error={taglineError}
            defaultValue={brand.brand.description}
          />
        ) : null}

        <Text weight="semi" margin="0 0 0">
          Categories
        </Text>
        <Text size="s" margin="0 0 s" colorPreset="secondary">
          Pick all that apply - this helps influencers find you
        </Text>
        <div style={{ position: "relative" }}>
          <Dropdown
            selectedOptions={selectedType}
            setSelectedOptions={setSelectedType}
            selectionMode="multiple"
            renderLabel={() => {
              return `${selectedType.length} categories selected`;
            }}
            options={brandCategories
              .sort((a, b) => a.localeCompare(b))
              .map((item) => ({
                label: item,
                value: item,
              }))}
          />
        </div>
        <div style={{ position: "relative" }}>
          <Text weight="semi" margin="xl 0 0">
            Cancellation Policy
          </Text>
          <Text size="s" margin="0 0 s" colorPreset="secondary">
            Require influencers to contact the venue for any changes within
            24hrs of the event
          </Text>
          <Dropdown
            selectedOptions={[cancellationPolicy]}
            setSelectedOptions={(value) =>
              setCancellationPolicy(value[0] as CancellationNotificationPolicy)
            }
            renderLabel={() => {
              return cancellationPolicyOptions.filter(
                (e) => e.value === cancellationPolicy
              )[0].label;
            }}
            selectionMode="single"
            options={cancellationPolicyOptions}
          />
        </div>
        <Submit
          type="submit"
          value={updateBrand.isLoading ? "Loading..." : "Save"}
          margin="xl 0 0 0"
        />
        <StyledFileUploaderRegular
          pubkey="04ec353cf089294c8374"
          apiRef={uploaderRef}
          sourceList="local,url,facebook,instagram,gdrive,gphotos,dropbox,onedrive"
          imageShrink="512x512"
          imgOnly
          cropPreset="1:1"
          multiple={false}
          onDoneClick={handleImageUpload}
          classNameUploader="uploader"
        />
      </form>
    </View>
  );
};

const StyledFileUploaderRegular = styled(FileUploaderRegular)`
  .uploader {
    [uc-simple-btn] {
      display: none;
    }
  }
`;
